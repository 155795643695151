import React from 'react';

const LandingPage = () => {
    return (
        <div>
            Добре дошли в клубния портал.
        </div>
    )
}

export default LandingPage